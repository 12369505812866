import React from "react";
import { ProjectPage } from "../../components/ProjectPage";
import TextContent from "../../components/shared/TextContent";
import Banner from "../../components/shared/Banner";
import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import styled from "styled-components";
import ZoomableImage from "../../components/shared/ZoomableImage";

const BannerImage = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  max-width: 40em;
  margin: 0 auto;

  ${ZoomableImage} {
    flex: 1 1 auto;
    margin: 0 0.5em;
  }
`;

export default function AnimalDugnadPage() {
  const image = useStaticQuery<{
    a: { childImageSharp: { fluid: FluidObject } };
    b: { childImageSharp: { fluid: FluidObject } };
  }>(graphql`
    query {
      a: file(relativePath: { eq: "work/animal-dugnad@1920.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      b: file(relativePath: { eq: "work/animal-dugnad-street.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <ProjectPage name="Animal Dugnad">
      <Banner>
        <BannerImage>
          <ZoomableImage fluid={image.a.childImageSharp.fluid} />
          <ZoomableImage fluid={image.b.childImageSharp.fluid} />
        </BannerImage>
      </Banner>
      <TextContent>
        <h1>Animal Dugnad</h1>
        <p>
          In a collaboration with Subvertising Norway, Laura produced a poster
          that would be used for subvertising. The act of subvertising consists
          in reclaiming public spaces by removing advertisement or replacing it
          with art. With the permission of the norwegian government, a group
          called Subvertising Norway peacefully helped to allocate art in
          different advertisement areas. For this particular project, art was
          exhibited during 17th of May, the national Nprwegian day, and sought
          after reflecting norwegian values. In this piece, animals collaborate
          to improve their environment, representing Dugnad, the traditional
          spring cleaning collaboration that many norwegian neighbourhoods tend
          to organize.
        </p>
      </TextContent>
    </ProjectPage>
  );
}
