import React, { ReactNode } from "react";
import styled from "styled-components";
import Layout from "./layout";
import SEO from "./seo";

const Wrapper = styled.div``;

export function ProjectPage({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) {
  return (
    <Wrapper>
      <Layout>
        <SEO title={name} />
        <div>{children}</div>
      </Layout>
    </Wrapper>
  );
}
